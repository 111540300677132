@use '../style/transitions';
@use '../style/badge';

.matero-sidemenu {
  width: calc(var(--sidenav-width) - 1px); // subtract border width
  padding: 0;
  margin: 0;
  list-style: none;

  .menu-item {
    display: block;
    height: auto;
    padding: 0;

    >.menu-heading {
      &:hover,
      &:focus {
        background-color: var(--sidemenu-heading-hover-background-color);
      }
    }

    &.active {
      > .menu-heading {
        color: var(--sidemenu-active-heading-text-color);
        background-color: var(--sidemenu-active-heading-background-color);

        &:hover,
        &:focus {
          background-color: var(--sidemenu-active-heading-hover-background-color);
        }
      }
    }

    &.expanded {
      background-color: var(--sidemenu-expanded-background-color);

      >.submenu {
        max-height: 2000px;
        visibility: visible;
      }

      >.menu-toggle>.menu-caret {
        transform: rotate(-180deg);
      }
    }
  }

  &.submenu {
    max-height: 0;
    padding-top: 0;
    overflow: hidden;
    visibility: hidden;
    transition: transitions.fast-out-slow(max-height), transitions.fast-out-slow(visibility);
    transform: translateZ(0) !important;
  }

  .menu-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .mat-icon.menu-icon {
    width: 18px;
    height: 18px;
    margin-right: 16px;
    font-size: 18px;
    line-height: 18px;

    [dir='rtl'] & {
      margin-right: auto;
      margin-left: 16px;
    }
  }

  .mat-icon.menu-caret {
    display: block;
    text-align: center;
    transition: transitions.fast-out-slow(transform);
  }

  .menu-name {
    flex: 1;
    text-align: initial;
  }

  .menu-name,
  .menu-label,
  .menu-badge {
    transition: transitions.swift-ease-out(opacity);
  }

  .menu-label,
  .menu-badge {
    @include badge.badge();
  }

  .menu-badge {
    border-radius: 50rem;
  }

  &.level-1>li>.menu-heading {
    padding-left: 50px;

    [dir='rtl'] & {
      padding-right: 50px;
      padding-left: 16px;
    }
  }

  &.level-2>li>.menu-heading {
    padding-left: 64px;

    [dir='rtl'] & {
      padding-right: 64px;
      padding-left: 16px;
    }
  }

  &.level-2 [class^='level-']>li>.menu-heading {
    padding-left: 80px;

    [dir='rtl'] & {
      padding-right: 80px;
      padding-left: 16px;
    }
  }
}
