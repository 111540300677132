.matero-auth-container {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding: 16px;
  background-color: #212121;
  background-image: url("../../../assets/images/background.jpg");
  background-size: 100%;
  background-size: cover;
}
