.customizer-handle {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1;

  [dir='rtl'] & {
    right: auto;
    left: 30px;
  }
}
